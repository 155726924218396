.transaction-detail-item {
  border-bottom: 1px solid #ababab;
}

@media screen and (max-width: 570px) {
  .transaction-detail-information {
    float: left;
    margin-top: 1rem;
  }
}
