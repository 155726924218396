
.card-view-section .card-mask-view {
  height:8.5rem !important
}

.card-view-section .card-mask-view .card-number {
  word-spacing: 1.5rem !important;
}
.card-view-section .secure-tag {
  text-align: right;
  font-size: 0.7rem;
  opacity: 0.7;
}

.card-detail-section {
  width:100%;
  justify-content: center;
  align-items: center;
}

.card-widget-box {
  background-color: transparent !important;
  padding: unset !important;
}

@media screen and (max-width:950px) {
  .card-detail-container {
    flex-direction: column;
  }
  .card-detail-section {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
}