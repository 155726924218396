.pin-fields-container > swd-pin-field {
    justify-content: center;
}

.pin-field {
    border: 1px solid #D8D8D8;
    box-shadow: 0 0 10px rgba(216, 216, 216,.5);
    border-radius: 0.3rem;
    font-size: 1rem;
    margin: 0.25rem;
    height: 3rem;
    outline: none;
    text-align: center;
    transition-duration: .25s;
    width: 2.5rem;
}

.pin-field:nth-child(3){
    margin-right: 2rem;
}

.pin-field::-webkit-outer-spin-button,
.pin-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pin-field {
  -moz-appearance: textfield;
}


@media screen and (max-width:350px) {
  .pin-field {
    height:2.5rem;
    width: 2rem;
  }
}