.menuText {
color: #fff;
}

.menuItem {
    padding: .7rem .8rem;
}
.menuItem-active {
    padding: .9rem .8rem;
}

.menuItem img {
    width:1.3rem;
}