@import url(../src/assets/fonts/fonts.css);

:root {
  --box-background: #eaeced;
  --box-border-radius: 20px;
  --content-left-right-margin: 2rem;
  --top-bar-height: 53px;
}

html {
  font-size: 16px;
}

body {
  margin: 0;

  padding: 0;

  box-sizing: border-box;
  font-family: 'RobotoRegular', sans-serif !important;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset !important;
  padding: unset;
}

.label {
  font-size: 0.9rem !important;
}

.title {
  font-size: 1.1rem;
}

.text-lg {
  font-size: 1.375rem; /*22px*/
}

.text-md {
  font-size: 1.125rem;
}

.text-base {
  font-size: 1rem !important; /*16px*/
}

.text-2sm {
  font-size: 0.938rem; /*15px*/
}

.text-sm {
  font-size: 0.875rem !important; /*14px*/
}

.text-xs {
  font-size: 0.75rem !important; /*12px*/
}

.text-2xs {
  font-size: 0.6rem !important;
}

.bold {
  font-weight: 600;
}

.weight-600 {
  font-weight: 600 !important;
  font-family: 'RobotoBold', sans-serif !important;
}

.weight-530{
  font-weight: 530;
  font-family: 'RobotoMedium', sans-serif !important;
}

.weight-500{
  font-weight: 500;
  font-family: 'RobotoMedium', sans-serif !important;
}

.weight-400 {
  font-weight: 400 !important;
  font-family: 'RobotoRegular', sans-serif !important;
}

.weight-300 {
  font-weight: 300;
  font-family: 'RobotoLight', sans-serif !important;
}

.modal-title {
  font-size: 1.15rem !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.pointer {
  cursor: pointer;
}

.cursor-block {
  cursor: not-allowed !important;
}

.p-5 {
  padding: 5px;
}

.br-10 {
  border-radius: 10px !important;
}

/* Flex Shorthand */

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.d-table {
  display: table;
}


.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.justify-even {
  justify-content: space-evenly;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start !important;
}

.d-flex-center {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Widths and Heights Shorthand*/
.fullWidth {
  width: 100%;
}

.half-width {
  width: 50%;
}

.width-65{
  width: 65%;
}

.width-fit-content{
  width: fit-content;
}

.fullHeight {
  height: 100%;
}

.width-lg {
  width:10rem !important; 
};

/*Material modal shorthands*/

.mat-modal-box {
  padding: 1.5rem 1rem 0px;
}
.mat-modal-header,
.mat-modal-body {
  margin-bottom: 1.5rem !important;
}


.mat-modal-footer {
  margin-bottom: 2rem !important;
}
.modal-footer-button {
  width: 8rem !important;
  font-size: 0.8rem !important;
}
.header-button-width {
  width: 7.188rem !important;
}

.header-button-height {
  height: 2.25rem !important;
}
.full-height {
  height: 100%;
}
.grid-height {
  height: 10rem !important;
}
.grid-border-right {
  border-right: solid 0.5px #658CBB!important;
}

.text-red {
  color: #fb1111 !important
}

/* Padding Shorthands*/

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.ml-0 {
  margin-left: unset !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left:auto !important;
}

.pl-1 {
  padding-left: 0.8rem !important;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-10 {
  padding-right: 10px;
}

.pr-8 {
  padding-right: 8rem;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-4 {
  padding-right: 4rem !important;
}

.mt{
  margin-top:2.5rem !important;
}
.mt-7{
  margin-top:4rem !important;


}

.break-word {
  word-break: break-all;
}

.pr-10{
  padding-right: 10px;
}

/* Other Elements*/

.transparent-background {
  background-color: transparent !important;
}

.layout-buttons button {
  width: 8rem;
}

/** Other CSS **/
.material-box {
  border-radius: var(--box-border-radius);
}

.custom-loader {
  padding: 2rem;
  height: unset;
}

.text-underline {
  text-decoration: underline;
}

.underline:hover {
  text-decoration: underline;
}

.Toastify__toast {
  box-shadow: 0 0 10px #bab4b4 !important;
  margin: unset !important;
  min-height: unset !important;
  padding: 0.5rem 0.6rem !important;
  font-size: 0.8rem !important;
  text-align: left !important;
  box-shadow: 0 4px 8px 0 #0000000a;
}

.Toastify__progress-bar {
  background: #fff !important;
  height: 2px !important;
}

.Toastify__close-button--light {
  color: #fff !important;
  opacity: 1 !important;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  position: absolute;
}

.Toastify__toast-icon svg {
  fill: #fff !important;
}

.Toastify__toast .pro .Toastify__toast-container--top-right {
  right: 0.8rem !important;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0f3a5e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #093253;
}

@media screen and (max-width: 500px) {
  .flex-header {
    flex-direction: column !important;
    justify-content: start !important;
    align-items: flex-start !important;
    gap: 1rem;
  }
}

@media screen and (min-width: 1700px) {
  html {
    font-size: 18px;
  }
}

@media only screen and (min-width: 351px) and (max-width: 500px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 350px) {
  html {
    font-size: 12px;
  }
}
