
.cardView {
    width:14rem;
    background: linear-gradient(270deg, #FFFFFF 0%, #DBDBDB 100%);
    box-shadow: 0 8px 15px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cardView .first-image{
    width:3.5rem;
}

.cardView .second-image {
    width: 2.5rem;
}

.remitImage {
    text-align: left;
  }
  
.card-type {
    font-size: .7rem;
    float: right;
    text-align: right;
}

.card-number {
    word-spacing: 1rem;
}

.security-valid-info{
    display: flex;
    gap:1.5rem;
}

.security-info, .valid-info {
    display: flex;
    gap:.5rem;
    align-items: center;
}

.card-info-title {
    font-size: .4rem;
    line-height: .5rem;
    text-transform: uppercase;
}

.card-info-detail {
    font-size: .7rem;
}

.cardTitle {
    font-size: .9rem;
    font-weight: 400;
}
