.step-ul {
  list-style-type: none;
  gap: 0.3rem;
  padding-left: 0rem;
}

.step-ul li {
  border-radius: 5px;
  width: 2.5rem;
  height: .6rem;
}

.steps-view p {
  font-size: 0.65rem;
}
