.custom-dropdown {
  height: 35px;
  border-radius: 5px;
  font-size: 0.85rem;
  width: 11rem;
  text-align: center;
  box-shadow: unset !important;
}

.dropdown-option {
  font-size: 0.85rem;
  cursor: pointer;
  color:inherit
}

.custom-dropdown-menu {
  width: 100%;
  background-color: #828184;
  color:#fff;
}

.custom-dropdown::after {
  position: absolute;
  right: 10px;
  font-size: 0.9rem;
  top: 40%;
  transform: translateY(-50%);
  border: inherit;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-option {
    padding: 2px 10px;
}
.dropdown-option:hover {
    background-color: #fff;
    color: #828184;
}