.sidebar {
    background-image: linear-gradient(141.82deg, #092338 0%, #0F395D 100%);
    height: 100%;
    position: fixed;
    z-index: 2;
}

.sidebar-collapse {
    width:70px;
}
.sidebar-expand {
    width:230px;
}

@media screen and (max-width:768px) {
    .sidebar {
        width: 70px;
    }
}
