.modal-cardView {
  width: 370px !important;
  height: 285px;
}

.modal-cardView .card-number {
  word-spacing: 3rem !important; 
}

@media screen and (max-width:350px) {
  .modal-cardView {
    width:275px !important
  }
}