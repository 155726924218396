.topbar {
    height: var(--top-bar-height);
    background-color: #fff;
    z-index: 1;
    box-shadow: 0 0px 20px 0px #eee;
    width: 100%;
    padding: 1.5rem 2rem;
}


.topbar img {
    width:1.3rem;
}

.user-info .avatar {
    font-size: .7rem;
    text-transform: uppercase;
}
.user-info .user-title {
    text-transform: initial;
}
@media screen and (max-width:768px) {
    .topbar .menu-img{
        display: none;
    }
}