
.preview-container {
    text-align: left;
    margin-left: -2rem;
    margin-right: -2rem;
}

.preview-title {
    padding:.4rem 2rem !important;  
}

.content-container{
    padding: 1.5rem 0rem 1.5rem 2rem;
    margin:unset;
}

.content-columns {
    margin: 1rem 0px;
    padding-left:unset;
}

.content-item {
    font-size: .9rem;
    overflow-wrap:anywhere;
}