.borderLessTable {
  width: 100%;
}

.action-image-div {
  gap: 0.8rem !important;
}

@media screen and (min-width: 990px) and (max-width: 1025px) {
  .action-image-div {
    gap: 0.3rem !important;
  }
}
.status {
  margin-left: -1rem !important;
  min-width: fit-content;
  text-align: center;
  color: #fff;
  border-radius: 7px;
}
