.signup-container {
  background-color: #eef5ff;
  min-height: 100vh;
}
.signup-container .header-img{
  width: 7rem;
}
.steps-container {
  max-width: 500px;
  min-width: 500px;
  background-color: #fff;

  border-radius: 20px;

  text-align: center;
  box-shadow: 0px 0px 15px rgb(204 204 204 / 60%);
  padding-left: 3rem;
  padding-right: 3rem;
}

.signup-footer {
  position: relative;
  width: 70%;
  font-size: .7rem;
  text-align: center;
}

@media screen and (max-width:550px) {
  .steps-container {
    width: 80% !important;
  }
}
@media screen and (max-width: 400px) {
  .steps-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
