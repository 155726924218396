.dashboard-container{
    color:#34495E;
    padding: 1.5rem var(--content-left-right-margin);
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--top-bar-height));
}

.dashboard-container > div:first-child {
    flex-grow: 1;
}

.content-collapse {
    padding-left: 70px;
    width: 100%;
}

.content-expand {
    padding-left:230px;
}