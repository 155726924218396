
@media screen and (max-width:500px) {
    .first-page-text,.last-page-text{
        display: none;
    }
}

@media screen and (max-width: 370px) {
    
    .material-pagination-item {
        margin:0px !important;
    }
}