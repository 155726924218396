.login-container {
    background-image: url('./loginBG.jpg');
    background-size: 100% 100%;
    height: 100%;
    min-height: 100vh;
    background-position: center;
    padding:7rem 0px 5rem;
}

.login-content-container{
    width:75%;
}

.login-content-logo{
    width:7rem;
}

.login-box {
    text-align: center;
    padding: 4rem 6.5rem 0px;
    height: 35rem;
    opacity: .9;
    width:30rem;
}

@media screen and (max-width:924px) {
    .login-content {
        margin-bottom: 3rem !important;
    }
    .login-box {
        padding:2rem 3rem 0px;
    }
}

@media screen and (max-width:500px) {
    .login-box{
        width:100%;
    }
}