.flex-button {
    justify-content: center;
    align-items: center;
    gap: .7rem;
}

@media screen and (max-width:350px) {
    .flex-button {
        flex-direction: column;
    }

    .flex-button button:nth-child(2){
        order:1;
    }
    .flex-button button:nth-child(1){
        order:2;
    }
}