.field-container {
  margin-bottom: 1.5rem;
  text-align: left;
}
.field-container :is(.form-control, .form-select) {
  border-radius: unset;
  padding: unset;
}
.field-container :is(.form-select, .form-control):focus {
  box-shadow: unset;
}
.form__field {
  font-family: inherit;
  width: 100%;
  font-size: 100%;
}

input:-webkit-autofill {
  background-color: #fff !important;
}

.field-container input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
}

.field-container input:-webkit-autofill,
.field-container input:-webkit-autofill:focus,
.field-container input:-webkit-autofill:hover,
.field-container input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s, color 600000s 0s;
  color: unset;
}

.error {
  font-size: 100%;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}
/* Chrome, Safari, Edge, Opera */
.field-container ::-webkit-outer-spin-button,
.field-container ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.field-container [type="number"] {
  -moz-appearance: textfield;
}

.field-container
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: unset;
}

.field-container
  .css-1480iag-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid;
}

.field-container .css-1480iag-MuiInputBase-root-MuiInput-root:after{
  border-radius: 1px;
}

.mui-menu-item {
  font-size: 0.9rem !important;
}

.date-field svg {
  width:1.2rem !important;
  height:1.2rem !important;
}

.fieldControlLabel {
  margin-left: unset !important;
}

.fieldControlLabel .field-checkbox {
  padding: 0px !important;
}

.fieldControlLabel .MuiFormControlLabel-label{
  font-size: .9rem !important;
  margin-left: 10px !important;
}

.field-container .password-mask-input::placeholder{
  color: #A3A9B2 !important;
}