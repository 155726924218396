.timer {
    margin-left: 5px;
}


.resend-label label {
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 500;
    opacity: .5;
}

.resend-label span {
    font-size: 1rem;
}

.resend-label label:not([disabled]){
    cursor:pointer;
    opacity: 1;
}

.resend-label label {
    cursor: no-drop;
    text-decoration: underline;
    text-transform: capitalize;
}