.content-section {
  margin-bottom: 4rem;
}

.content-section img {
  width: 5.5rem;
}

.content-section .question-text {
  margin-top: 1rem;
}
.content-section p {
  font-size: 0.8rem;
}
