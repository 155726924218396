.cardholder-view {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.cardholder-view .header {
  margin-bottom: 1rem !important;
}

.cardholder-view .agreement-section {
  text-align: left;
  font-size: 0.7rem;
  gap: 0.5rem;
}