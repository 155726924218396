.filter-box .filter-button {
  background-color: #fff;
  text-transform: capitalize;
  width: 6.5rem;
}

.filter-button:hover {
  background-color: #fff !important;
  border: unset;
}

.transaction-history img {
  width: 1.3rem;
}

.date-range-field {
  width: 15rem !important;
  font-size: 0.8rem !important;
}

.statement-button {
  float: right;
  width: 10rem;
}


.p-tag {
  padding-left: 5px;
  padding-top: 2px;
}

@media screen and (max-width:900px) {
    .recent-transaction-header{
        justify-content: space-between !important;
    }
}

@media screen and (max-width:420px) {
    .transaction-statement-button, .transaction-statement-button button {
    width:100% ;
    }
}
@media screen and (max-width: 370px) {
  .date-range-field {
    width: 12rem !important;
  }
}
