.custom-button {
  cursor: pointer;
  border: unset;
  height: 40px;
  border-radius: 5px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-button span {
  margin-right: 10px;
}

.back-button svg {
  margin-right: .3rem;
}


.custom-button svg {
  font-size: 1.3rem;
}

  
.custom-button:disabled {
  opacity: 0.5;
  cursor: no-drop;
}


@media screen and (max-width:350px)  {
  .custom-button {
    width: 100% !important;
    height:35px;
  }
}

