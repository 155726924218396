
.sidebar-img-container{
    height: 80px;
}

.sidebar-img-container img {
    width: 70%;
}

.sidebar-img-container img.expandImg {
    width: 40%;
}